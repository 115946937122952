/* eslint-disable */
import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import { parseSeo } from '../../helpers/seo'
import { AppContext } from '../../config/context'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'
import { Heading } from '../../components/Heading'
import { Table } from '../../components/Table'
import { CompareStickyHeader } from '../../components/CompareStickyHeader'
import { Breadcrumbs } from '../../components/Breadcrumbs'
import { ProductCard } from '../../components/ProductCard'
import { CompareTable } from '../../components/CompareTable'
import { PageContent } from '../../components/PageContent'
import { getUrl } from '../../helpers/url'
import { useTranslation } from '../../hooks/useTranslation'

export default ({ data, pageContext }) => {
  const page = data.wordpress.page
  const { seo } = page

  const [state, dispatch] = useContext(AppContext)
  const { products, productsToCompare } = state

  const getTranslation = useTranslation()

  const parsedProducts = products
    .map(product => {
      const { productSpecTree } = product

      const parsedProductSpecTree = (raw => {
        try {
          return JSON.parse(raw)
        } catch (err) {
          return {}
        }
      })(productSpecTree)

      return { ...product, parsedProductSpecTree: parsedProductSpecTree }
    })
    .filter(product => productsToCompare.includes(product.productId))

  const allSpecs = parsedProducts
    ? parsedProducts.reduce((acc, product) => {
        const { parsedProductSpecTree } = product

        let specs = []

        for (const [key, value] of Object.entries(parsedProductSpecTree)) {
          for (const [secondLevelKey, secondLevelValue] of Object.entries(
            parsedProductSpecTree[key]
          )) {
            specs.push(secondLevelKey)
          }
        }

        return [...new Set([...acc, ...specs])]
      }, [])
    : null

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <Breadcrumbs breadcrumbs={seo.breadcrumbs} />
      <PageContent>
        <CompareTable>
          <CompareStickyHeader>
            <Table>
              <Table.Body>
                <Table.Tr>
                  <Table.Th></Table.Th>
                  {parsedProducts.map((product, index) => {
                    const { id, name } = product
                    return (
                      <Table.Td key={id}>
                        <Heading size={5} type={'h5'}>
                          {name}
                        </Heading>
                      </Table.Td>
                    )
                  })}
                </Table.Tr>
              </Table.Body>
            </Table>
          </CompareStickyHeader>

          <Table>
            <Table.Head>
              <Table.Tr>
                <Table.Th></Table.Th>
                <Table.Th colspan={4}>
                  <caption>
                    <Heading type={'h1'} size={1}>
                      {getTranslation('productComparisonText')}
                    </Heading>
                  </caption>
                </Table.Th>
              </Table.Tr>
              <Table.Tr>
                <Table.Th></Table.Th>

                {parsedProducts.map((product, index) => {
                  const {
                    uri,
                    acfAttributes: { productSerie },
                  } = product

                  return (
                    <Table.Th key={index}>
                      <ProductCard product={product} atComparePage />
                      <Table.Link to={getUrl(uri)}>
                        {getTranslation('readMoreAndBuyText')}
                      </Table.Link>
                    </Table.Th>
                  )
                })}
              </Table.Tr>
            </Table.Head>
            <Table.Body>
              {allSpecs.map((spec, index) => {
                return (
                  <Table.Tr key={spec}>
                    <Table.Th>
                      <span>{spec}</span>
                    </Table.Th>

                    {parsedProducts.map((product, productIndex) => {
                      const { parsedProductSpecTree } = product

                      let specTerms = []

                      for (const [key, value] of Object.entries(
                        parsedProductSpecTree
                      )) {
                        if (parsedProductSpecTree[key].hasOwnProperty(spec)) {
                          specTerms = [
                            ...parsedProductSpecTree[key][spec].specs,
                          ]
                        }
                      }

                      return specTerms.length > 0 ? (
                        <Table.Td key={`product${productIndex}`}>
                          {specTerms.map((term, termIndex) => {
                            const { name, url } = term

                            return (
                              <span key={`term${termIndex}`}>
                                {name}
                                {termIndex < specTerms.length - 1 && ', '}
                              </span>
                            )
                          })}
                        </Table.Td>
                      ) : (
                        <Table.Td key={`product${productIndex}`}>-</Table.Td>
                      )
                    })}
                  </Table.Tr>
                )
              })}
            </Table.Body>
          </Table>
        </CompareTable>
      </PageContent>
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id) {
        ...Single_Page
      }
    }
  }
`
