import styled from 'styled-components'
import { spacing, colors, typography } from '../../styles/utilities/variables'
import StyledTable from '../Table/style'

const StyledCompareTable = styled.div`
  ${StyledTable} {
    /* min-width: 200%; <-- to test sticky side header
    min-height: 300rem; <-- to test sticky header */
    width: auto;

    &:not(:first-child) {
      margin: 1%;
      width: 98%;
    }
  }

  ${StyledTable.Head} {
    th {
      padding-right: 0.8em;
    }

    tr:first-child {
      border-bottom: none;
      padding: 0 ${spacing.xLarge} ${spacing.xLarge} 0;
    }

    tr:nth-child(2) {
      th {
        width: 20%;
        padding: ${spacing.small} ${spacing.small} 0;
        z-index: 1;
        background-color: ${colors.adaptedLightBlueBackground};
      }
    }

    tr:last-child {
      th:not(:first-child) {
        padding: 0 ${spacing.xLarge} ${spacing.xLarge} 0;
      }
    }
  }

  ${StyledTable.Body} {
    th  {
      width: 20%;
      position: sticky;
      background-color: ${colors.adaptedLightBlueBackground};
      left: 0;
      padding-right: 0.8em;
    }
    td {
      padding-right: 0.8em;
      width: 20%;
    }
  }

  ${StyledTable.Th} {
    h1 {
      text-transform: none;
    }

    p {
      font-family: ${typography.primaryFont};
      text-transform: none;
    }

    div:first-child {
      margin-bottom: ${spacing.xSmall};
    }
  }
`

export default StyledCompareTable
