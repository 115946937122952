import styled, { css } from 'styled-components'
/* Path below could differ */
import { colors, cssEasings } from '../../styles/utilities/variables'

const StyledCompareStickyBar = styled.div`
  background-color: ${colors.adaptedLightBlueBackground};
  text-transform: uppercase;
  letter-spacing: 0.04em;
  border-bottom: 1px solid ${colors.secondaryLightBlue};
  transition: all 0.4s ${cssEasings.easeOutQuart};
  position: sticky;
  display: block;
  top: 7rem;
  z-index: 1;
  opacity: 1;
  tr {
    border-bottom: none;
  }
  table {
    width: 98% !important;
    margin: 0 1%;
  }
  td {
    width: 20%;
  }
  th {
    width: 20%;
  }

  ${props =>
    props.hidden &&
    css`
      opacity: 0;
    `};
`

export default StyledCompareStickyBar
