import React from 'react'
import PropTypes from 'prop-types'
import StyledCompareTable from './style'

const CompareTable = props => {
  const { children } = props
  return <StyledCompareTable>{children}</StyledCompareTable>
}

CompareTable.propTypes = {
  children: PropTypes.any,
}

export { CompareTable }
