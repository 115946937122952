/* eslint-disable */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getIsClient } from '../../helpers/utilities'
import StyledCompareStickyHeader from './style'

const CompareStickyHeader = props => {
  const isClient = getIsClient()
  const { children } = props
  const [scrolling, setScrolling] = useState(false)
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    if (!isClient) return false

    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
      if (e.target.documentElement.scrollTop > 600) setScrolling(true)
      else setScrolling(false)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  return (
    <StyledCompareStickyHeader hidden={!scrolling}>
      {children}
    </StyledCompareStickyHeader>
  )
}

CompareStickyHeader.propTypes = {
  children: PropTypes.any,
}

export { CompareStickyHeader }
